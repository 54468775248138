import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

function BottomModal({ showModal, setShowModal, children, bottomModalClassName }) {
	return (
		<BottomSheet
			open={showModal.show}
			onDismiss={() => setShowModal({ ...showModal, show: false })}
			className={`bottom-modal ${bottomModalClassName}`}
			snapPoints={({ minHeight }) => [minHeight]}
			defaultSnap={({ minHeight }) => minHeight}
		>
			{children}
		</BottomSheet>
	);
}

export default BottomModal;
