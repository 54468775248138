import axios from 'axios'
import { toast } from 'react-toastify'
import store from 'store';

// import i18n from './i18n'

const baseURL = 'https://backend.mison.uz/api';
// const baseURL = 'https://0b26-213-230-72-0.ngrok-free.app';

const axiosClient = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	},
});

export const get = async (url) => {
	const data = await axiosClient.get(url, {
		headers: {
			"Authorization": store?.getState()?.information?.client?.token ? 'Bearer ' + store?.getState()?.information?.client?.token : '',
			"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
			"Language": localStorage.getItem("I18N_LANGUAGE")
		}
	})
		.catch(e => toast.error(e))
	return data;
}

export const post = async (url, payload, settings = { showError: true }) => {

	const data = await axiosClient.post(url, payload, {
		headers: {
			"Authorization": store?.getState()?.information?.client?.token ? 'Bearer ' + store?.getState()?.information?.client?.token : '',
			"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
			"Language": localStorage.getItem("I18N_LANGUAGE"),
		}
	})
		.catch(e => {
			if (settings.showError) {
				toast.error(e?.response?.data?.message)
			}
			return e.response;
		})

	return data;
}


export function httpOk(response) {
	if (response?.status < 400) {
		return true
	} else {
		return false
	}
}