import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Shimmer() {
	const menu = [
		{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
	]

	function getWidth() {
		if (window.innerWidth > 880) {
			return window.innerWidth / 4 - 20
		}
		if (window.innerWidth > 576) {
			return window.innerWidth / 3 - 23
		}
		return window.innerWidth / 2 - 30
	}

	return (
		<>
			<SkeletonTheme>
				<div className="tg-header">
					{/* <div className="text-center">
						<Skeleton width={250} height={40} borderRadius={6} style={{ borderBottomRightRadius: '32px' }} />
					</div> */}

					<div className="menu">
						{menu?.map((item, index) => (
							<Skeleton
								width={Math.floor(Math.random() * (150 - 80 + 1)) + 80}
								height={40}
								containerClassName="d-flex align-items-center me-2"
								borderRadius={16}
								key={index}
							>
							</Skeleton>
						))}
					</div>
				</div>

				<div className="home">
					{menu?.map((item, index) => (
						<div key={index}>
							<div className="menu-title" >
								<Skeleton count={1} width={150} borderRadius={10} />
							</div>
							<div className="products">
								{menu?.map((item2, index2) => (
									<Skeleton
										height={260}
										width={getWidth()}
										borderRadius={16}
										key={index2}
									// className="product-card"
									>

									</Skeleton>
								))}
							</div>
						</div>
					))}
				</div>
			</SkeletonTheme>
		</>
	)
}

export default Shimmer